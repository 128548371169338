import React, { useEffect, useState } from "react";
import { CrossIcon } from "../assets/Icons";
import { ToggleButton } from "../components";
import { useCreateFeatureMutation } from "../store/services/featuredService";
import { useSelector } from "react-redux";

const FeaturedModel = ({ openModal, setOpenModal }) => {


  const words = useSelector((state) => state.authReducer.words);

  const [isActive, setIsActive] = useState(true);
  const [formData, setFormData] = useState({
    title: "",
    type: "",
    reorder: 0,
    rank: 0,
    status: isActive ? "Active" : "InActive",
  });
  
  const [submitData, response] = useCreateFeatureMutation();
  

  const submitFormData = () => {
    submitData(formData);
  };


  useEffect(() => {
    if (response?.isSuccess) {
      setOpenModal(false);
      window.location.reload();
    }
  }, [response]);

  return (
    <div className="flex flex-col shadow-md-custom border border-[#D8D8D8] p-4">
      <div className="flex flex-col gap-3 items-end mb-4">
        <div
          className="flex items-center justify-center h-8 w-8 bg-[#EFEFEF] rounded-full p-1 hover:cursor-pointer"
          onClick={() => setOpenModal(false)}
        >
          <CrossIcon />
        </div>
        <div className="flex items-center justify-between gap-3 w-full">
          <h1 className="text-black text-xl font-medium">
            {words['Add Featured Section']}
          </h1>
          <div className="flex items-center gap-1.5">
            <div className="text-base">{words['Active']}</div>
            <ToggleButton checked={isActive} setChecked={setIsActive} />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">{words['Title']}</label>
          <input
            type="text"
            // placeholder="Enter words['Title']}"
            name="title"
            value={formData.title}
            onChange={(e) =>
              setFormData({ ...formData, title: e.target.value })
            }
            className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">
            {words['Section Types']}
          </label>
          <input
            type="text"
            // placeholder="Enter Section Type"
            name="type"
            value={formData.type}
            onChange={(e) => setFormData({ ...formData, type: e.target.value })}
            className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
          />
        </div>

        <div className="flex items-center justify-center">
          <button
            className="button rounded-full !py-2 !px-10"
            onClick={(e) => submitFormData()}
          >
            {words['Add Featured']}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeaturedModel;

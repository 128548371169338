// src/MapComponent.js
import React, { useState, useCallback, useRef } from 'react';
import { GoogleMap, LoadScript, MarkerF, Autocomplete } from '@react-google-maps/api';
// 
import Input from './Input';

const containerStyle = {
    width: '100%',
    height: '400px'
};

const center = {
    lat: 19.4966016,
    lng: 74.3604224
};

const MapComponent = ({ apiKey, onLocationFetch = () => { }, initialLocation, shouldGetUserLocation = true, currentLocation }) => {

    const [location, setLocation] = useState(initialLocation?.lat ? initialLocation : center);
    const autocompleteRef = useRef(null);
    const [markerLoaded, setMarkerLoaded] = useState(false);

    const onLoad = marker => {

        setMarkerLoaded(true);
        console.log('marker: ', marker);
    };

    const onDragEnd = (e) => {
        setLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        onLocationFetch({
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        })
    };

    const onMapClick = (e) => {
        setLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
        onLocationFetch({
            lat: e.latLng.lat(),
            lng: e.latLng.lng()
        })
    };

    // const getUserLocation = useCallback(() => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //             position => {
    //                 const { latitude, longitude } = position.coords;
    //                 setLocation({ lat: latitude, lng: longitude });
    //                 onLocationFetch({
    //                     lat: latitude,
    //                     lng: longitude
    //                 })
    //             },
    //             error => console.log(error),
    //             { enableHighAccuracy: true }
    //         );
    //     }
    // }, []);

    const getUserLocation = useCallback(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const { latitude, longitude } = position.coords;
                    setLocation({ lat: latitude, lng: longitude });
                    const geocoder = new window.google.maps.Geocoder();
                    const latlng = { lat: latitude, lng: longitude };
                    geocoder.geocode({ location: latlng }, (results, status) => {
                        if (status === "OK" && results[0]) {
                            let city = '';
                            console.log('the results are in the', results)
                            for (const component of results[0].address_components) {
                                if (component.types.includes('locality')) {
                                    city = component.long_name;
                                    break;
                                }
                            }

                            onLocationFetch({
                                lat: latitude,
                                lng: longitude,
                                city: city,
                                current_location: results[0].formatted_address
                            });
                            // Optionally, update state or props with city name
                            // setFormData({ ...formData, current_location: city });
                        } else {
                            onLocationFetch({
                                lat: latitude,
                                lng: longitude
                            });
                            // console.error('Geocoder failed due to: ' + status);
                        }
                    });
                },
                error => console.log(error),
                { enableHighAccuracy: true }
            );
        }
    }, [onLocationFetch]);



    // src/MapComponent.js
    const onPlaceChanged = () => {
        const place = autocompleteRef.current.getPlace();
        if (place.geometry) {

            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            // Extract the city name from the address components
            let city = '';
            for (const component of place.address_components) {
                if (component.types.includes('locality')) {
                    city = component.long_name;
                    break;
                }
            }

            onLocationFetch({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
                city: city,
                current_location: place.formatted_address
            });


            // console.log('Selected Place:', {
            //     city,
            //     lat,
            //     lng
            // });

            setLocation({
                lat,
                lng
            });

            // Optionally, update form data or state with the city name
            // setFormData({ ...formData, current_location: city });
        }
    };





    return (
        <div style={{ width: "100%" }}>

            {/* <LoadScript googleMapsApiKey={apiKey} libraries={['places']}> */}
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={location}
                zoom={10}
                onClick={onMapClick}
                onLoad={() => shouldGetUserLocation && getUserLocation()}
            >


                <MarkerF
                    position={location}
                    onLoad={onLoad}
                    draggable={true}
                    onDragEnd={onDragEnd}
                />





            </GoogleMap>

            <div className="w-full flex flex-col gap-2 mb-3">
                <div className="">{"Current Location"}</div>

                <Autocomplete
                    onLoad={ref => (autocompleteRef.current = ref)}
                    onPlaceChanged={onPlaceChanged}>
                    <Input
                        type="text"
                        name="current_location"
                        placeholder="Enter a location"

                        value={currentLocation}
                    // onChange={handleInput}
                    // required
                    />

                </Autocomplete>

            </div>

            {/* </LoadScript> */}




        </div >
    );
};

export default MapComponent;

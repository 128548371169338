import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Input, Layout, ToggleButton, UploadButton } from "../../components";
import { useCreateStoreMutation } from "../../store/services/storeService";
import { postRequestFormData } from "../../Api";
import { useSelector } from "react-redux";
import MapComponent from "../../components/MapComponent";
import { MAP_API_KEY } from "../../Api/constants";

const StoreCreate = () => {

  const words = useSelector((state) => state.authReducer.words);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [atStore, seAtStore] = useState(false);
  const [atHome, setAtHome] = useState(false);

  const [logo, setLogo] = useState(null);
  const [photo1, setPhoto1] = useState(null);
  const [photo2, setPhoto2] = useState(null);
  const [photo3, setPhoto3] = useState(null);
  const [crFile, setCrFile] = useState(null);
  const [idFile, setIdFile] = useState(null);
  const [saveStore, response] = useCreateStoreMutation();


  const [formData, setFormData] = useState({
    store_name: "",
    user_name: "",
    store_email: "",
    store_number: "",
    store_password: "",
    store_visting_charges: "",
    is_at_home: false,
    is_at_store: false,
    description: "",
    is_sunday: false,
    sunday_from: "",
    sunday_to: "",
    is_monday: false,
    monday_from: "",
    monday_to: "",
    is_tuesday: false,
    tuesday_from: "",
    tuesday_to: "",
    is_wednesday: false,
    wednesday_from: "",
    wednesday_to: "",
    is_thursday: false,
    thursday_from: "",
    thursday_to: "",
    is_friday: false,
    friday_from: "",
    friday_to: "",
    is_saturday: false,
    saturday_from: "",
    saturday_to: "",
    latitude: "",
    longitude: "",
    current_location: "",
    city: "",
    bank_name: "",
    account_number: "",
    account_name: "",
    status: "",
  });


  const handleUploadLogo = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setLogo(event.target.files[0]);
    }
  };

  const handleUploadCR = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setCrFile(event.target.files[0]);
    }
  };

  const handleUploadID = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setIdFile(event.target.files[0]);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleToggleChange = (day, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [`is_${day}`]: value,
    }));
  };

  const handleTimeChange = (day, period, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [`${day}_${period}`]: value,
    }));
  };

  const PerDay = ({
    day,
    isOn,
    fromTime,
    toTime,
    onToggleChange,
    onTimeChange,
  }) => {
    return (
      <div className="flex items-center gap-3">
        <div className="flex-1 flex items-center">
          <div className="text-base text-black font-medium">
            {words[day.charAt(0).toUpperCase() + day.slice(1)]}
          </div>
        </div>
        <div className="flex items-center gap-5">
          <input
            type="time"
            value={fromTime}
            onChange={(e) => onTimeChange(day, "from", e.target.value)}
            className="text-sm text-black bg-themeColor/30 border-[1px] border-themeColor px-2 py-1 rounded-lg"
          />
          <div className="text-base">To</div>
          <input
            type="time"
            value={toTime}
            onChange={(e) => onTimeChange(day, "to", e.target.value)}
            className="text-sm text-black bg-themeColor/30 border-[1px] border-themeColor px-2 py-1 rounded-lg"
          />
        </div>
        <div className="flex-1 flex items-center justify-end">
          <button
            type="button"
            className={`w-[52px] h-[24px]  rounded-full border-[1px]  flex items-center relative ${isOn
              ? "border-green-500 bg-green-600/20"
              : "border-[#F21531] bg-[#F21531]/20"
              }`}
            onClick={() => onToggleChange(day, !isOn)}
          >
            <div
              className={`h-[17px] w-[17px] rounded-full  absolute animation ${isOn ? "left-7 bg-green-600" : "left-1 bg-[#F21531]"
                }`}
            />
            {isOn && <div className="absolute left-1 text-sm">off</div>}
            {!isOn && <div className="absolute right-1 text-sm">on</div>}
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (atStore) {
      setFormData({ ...formData, is_at_store: atStore });
    }
    if (atHome) {
      setFormData({ ...formData, is_at_home: atHome });
    }
  }, [atStore, atHome]);



  const handleSubmitSetting = async (e) => {
    e.preventDefault();
    // const data = new FormData();
    const body = {
      store_name: formData.store_name,
      store_email: formData.store_email,
      store_number: formData.store_number,
      store_password: formData.store_password,
      store_visting_charges: formData.store_visting_charges,
      is_at_home: atHome,
      is_at_store: atStore,
      description: formData?.description,
      is_sunday: formData?.is_sunday,
      sunday_from: formData?.sunday_from,
      sunday_to: formData?.sunday_to,
      is_monday: formData?.is_monday,
      monday_from: formData?.monday_from,
      monday_to: formData?.monday_to,
      is_tuesday: formData?.is_tuesday,
      tuesday_from: formData?.tuesday_from,
      tuesday_to: formData?.tuesday_to,
      is_wednesday: formData?.is_wednesday,
      wednesday_from: formData?.wednesday_from,
      wednesday_to: formData?.wednesday_to,
      is_thursday: formData?.is_thursday,
      thursday_from: formData?.thursday_from,
      thursday_to: formData?.thursday_to,
      is_friday: formData?.is_friday,
      friday_from: formData?.friday_from,
      friday_to: formData?.friday_to,
      is_saturday: formData?.is_saturday,
      saturday_from: formData?.saturday_from,
      saturday_to: formData?.saturday_to,
      latitude: formData?.latitude,
      longitude: formData?.longitude,
      current_location: formData?.current_location,
      city: formData?.city,
      bank_name: formData?.bank_name,
      account_number: formData?.account_number,
      account_name: formData?.account_name,
      status: isActive,
      logo: logo,
      photo1: photo1,
      photo2: photo2,
      photo3: photo3,
      cr_certification: crFile,
      id_certification: idFile,
    }


    const onSuccess = (res) => {
      setLoading(false)
      toast.success("Data Save Successfully");
      navigate("/dashboard/stores");
    }

    const onError = (err) => {
      toast.error(err?.error ?? "Something wrong try again later!")
      setLoading(false)
      console.log('err', err);
    }
    setLoading(true)
    await postRequestFormData(body, '/api/stores/store', true, onSuccess, onError);


    // saveStore(data);
  };

  useEffect(() => {
    if (response.isSuccess) {
      toast.success("Data Save Successfully");
      navigate("/dashboard/stores");
    }
    if (response.isError) {
      toast.error("Something wrong try again later!");
    }
  }, [response]);

  return (
    <Layout pageTitle={words["Create store"]}>
      <div className="w-full min-h-screen flex pb-10">
        <div className="wrap wrapWidth flex flex-col gap-6">
          <form onSubmit={handleSubmitSetting} className="w-full gap-6">
            <div className="w-full flex gap-6 mb-6">
              <div className="w-full grid-box px-5 pt-4 pb-12 border-solid border-[1px] border-[#D8D8D8] rounded-[20px] flex flex-col">
                <div className="w-full flex items-center mb-6">
                  <div className="text-base font-bold flex-1">
                    {words["store information"]}
                  </div>
                  <div className="flex items-center gap-1.5">
                    <div className="text-base">{words["Active"]}</div>
                    <ToggleButton checked={isActive} setChecked={setIsActive} />
                  </div>
                </div>
                <div className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                  <div className="text-base text-black">{words["Store Name"]}</div>
                  <Input
                    placeholder={words["Store Name"]}
                    name="store_name"
                    value={formData.store_name}
                    onChange={handleInput}
                    required
                  />
                </div>
                <div className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
                  <div className="text-base text-black">{words["Visiting Charges"]}</div>
                  <Input
                    placeholder={words["Visiting Charges"]}
                    name="store_visting_charges"
                    value={formData.store_visting_charges}
                    onChange={handleInput}
                    required
                  />
                </div>
                <div className="w-full flex items-center gap-8 mb-[24px]">
                  <div className="flex items-center gap-1.5">
                    <div className="text-base">{words["At Store"]}</div>
                    <ToggleButton checked={atStore} setChecked={seAtStore} />
                  </div>
                  <div className="flex items-center gap-1.5">
                    <div className="text-base">{words["At Home"]}</div>
                    <ToggleButton checked={atHome} setChecked={setAtHome} />
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="text-base">{words["About this store"]}</div>
                  <textarea
                    className="input h-[110px]"
                    name="description"
                    value={formData.description}
                    onChange={handleInput}
                    placeholder="Enter About Store here..."
                  />
                </div>
              </div>
              <div className="w-full grid-box px-4 py-5 border-solid border-[1px] border-[#D8D8D8] rounded-[20px]">
                <div className="w-full flex items-center mb-6">
                  <div className="text-base font-bold flex-1">{words["Images"]}</div>
                </div>
                <div className="w-full flex flex-col mb-8">
                  <div className="text-base mb-2">{words["Logo"]}</div>
                  <div className="w-full flex gap-5">
                    <div className="w-full max-w-[250px]">
                      <UploadButton
                        label=""
                        text="Add image 80X20"
                        onClick={() => {
                          document.getElementById("uploadLogo")?.click();
                        }}
                      />
                      <input
                        accept="image/jpeg, image/png, image/jpg, image/webp"
                        name="file-upload"
                        id="uploadLogo"
                        type="file"
                        className="sr-only"
                        onChange={handleUploadLogo}
                        style={{ display: "none" }}
                      />
                    </div>
                    {logo && (
                      <img
                        src={window.URL.createObjectURL(logo)}
                        // src={BASE_URL + removePublicFromUrl(logo)}
                        className="h-[130px] object-contain max-w-[200px]"
                      />
                    )}
                  </div>
                </div>
                <div className="w-full flex flex-col">
                  <div className="text-base mb-2">{words["Photos"]}</div>
                  <div className="w-full grid grid-cols-3 gap-3">
                    <div className="w-full flex flex-col gap-5">
                      <div className="w-full max-h-[135px]">
                        <UploadButton
                          label="Photo 1"
                          text=""
                          onClick={() => {
                            document.getElementById("uploadPhoto1")?.click();
                          }}
                        // className="max-h-[135px]"
                        />
                        <input
                          accept="image/jpeg, image/png, image/jpg, image/webp"
                          name="file-upload"
                          id="uploadPhoto1"
                          type="file"
                          className="sr-only"
                          onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                              setPhoto1(e.target.files[0]);
                            }
                          }}
                          style={{ display: "none" }}
                        />
                      </div>
                      {photo1 && (
                        <img
                          src={window.URL.createObjectURL(photo1)}
                          className="h-[130px] object-contain max-w-[200px]"
                        />
                      )}
                    </div>
                    <div className="w-full flex flex-col gap-5">
                      <div className="w-full max-h-[135px]">
                        <UploadButton
                          label="Photo 2"
                          text=""
                          onClick={() => {
                            document.getElementById("uploadPhoto2")?.click();
                          }}
                        // className="max-h-[135px]"
                        />
                        <input
                          accept="image/jpeg, image/png, image/jpg, image/webp"
                          name="file-upload"
                          id="uploadPhoto2"
                          type="file"
                          className="sr-only"
                          onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                              setPhoto2(e.target.files[0]);
                            }
                          }}
                          style={{ display: "none" }}
                        />
                      </div>
                      {photo2 && (
                        <img
                          src={window.URL.createObjectURL(photo2)}
                          className="h-[130px] object-contain max-w-[200px]"
                        />
                      )


                      }
                    </div>
                    <div className="w-full flex flex-col gap-5">
                      <div className="w-full max-h-[135px]">
                        <UploadButton
                          label="Photo 3"
                          text=""
                          onClick={() => {
                            document.getElementById("uploadPhoto3")?.click();
                          }}
                        // className="max-h-[135px]"
                        />
                        <input
                          accept="image/jpeg, image/png, image/jpg, image/webp"
                          name="file-upload"
                          id="uploadPhoto3"
                          type="file"
                          className="sr-only"
                          onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                              setPhoto3(e.target.files[0]);
                            }
                          }}
                          style={{ display: "none" }}
                        />
                      </div>
                      {photo3 && (
                        <img
                          src={window.URL.createObjectURL(photo3)}
                          className="h-[130px] object-contain max-w-[200px]"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex gap-6 mb-6">
              <div className="w-full grid-box px-4 py-5 border-solid border-[1px] border-[#D8D8D8] rounded-[20px]">
                <div className="w-full flex items-center mb-8">
                  <div className="text-base font-bold flex-1">{words["Working Days"]}</div>
                </div>
                <div className="w-full flex flex-col gap-5">
                  {[
                    "sunday",
                    "monday",
                    "tuesday",
                    "wednesday",
                    "thursday",
                    "friday",
                    "saturday",
                  ].map((day, index) => (
                    <PerDay
                      key={index}
                      day={day}
                      isOn={formData[`is_${day}`]}
                      fromTime={formData[`${day}_from`]}
                      toTime={formData[`${day}_to`]}
                      onToggleChange={handleToggleChange}
                      onTimeChange={handleTimeChange}
                    />
                  ))}
                </div>
              </div>
              <div className="w-full grid-box px-4 py-4 border-solid border-[1px] border-[#D8D8D8] rounded-[20px]">
                <div className="w-full flex items-center mb-8">

                  <MapComponent
                    onLocationFetch={(location) => {
                      setFormData({
                        ...formData,
                        latitude: location.lat,
                        longitude: location.lng,
                        city: location.city ?? formData.city,
                        current_location: location.current_location ?? null
                      });
                    }}
                    apiKey={MAP_API_KEY} />



                  {/* <div className="text-base font-bold flex-1">
                    {words["Location Information"]}
                  </div> */}
                </div>
                {/* <div className="w-full h-[220px] bg-[#EFEFEF] border-[1px] border-[#808080] rounded-[10px] flex items-center justify-center mb-4">
                  <div className="text-base text-[#808080]">{words["Map"]}</div>
                </div> */}
                {/* <div className="w-full flex flex-col gap-2 mb-3">
                  <div className="">{words["Current Location"]}</div>
                  <Input
                    type="text"
                    name="current_location"
                    value={formData.current_location}
                    onChange={handleInput}
                    required
                  />
                </div> */}
                <div className="w-full flex flex-col gap-2">
                  <div className="">{words["City"]}</div>
                  <Input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInput}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex gap-6 mb-6">
              <div className="w-full grid-box px-4 py-5 border-solid border-[1px] border-[#D8D8D8] rounded-[20px]">
                <div className="w-full flex items-center mb-8">
                  <div className="text-base font-bold flex-1">
                    {words["Personal Information"]}
                  </div>
                </div>
                <div className="w-full grid grid-cols-3 gap-6">
                  <div className="w-full flex flex-col gap-2">
                    <div className="text-base text-black">{words["Name"]}</div>
                    <Input
                      type="text"
                      name="user_name"
                      value={formData.user_name}
                      onChange={handleInput}
                      required
                    />
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <div className="text-base text-black">{words["Email"]}</div>
                    <Input
                      type="email"
                      name="store_email"
                      value={formData.store_email}
                      onChange={handleInput}
                      required
                    />
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <div className="text-base text-black">{words["CR Certification"]}</div>
                    <button
                      type="button"
                      className="w-full h-[43px] rounded-[8px] bg-themeColor/30 text-[15px] font-semibold text-themeColor"
                      onClick={() => {
                        document.getElementById("CRUploadID")?.click();
                      }}
                    >
                      {crFile ? crFile.name : " Upload CR"}
                    </button>
                    <input
                      accept=".pdf"
                      name="file-upload"
                      id="CRUploadID"
                      type="file"
                      className="sr-only"
                      onChange={handleUploadCR}
                      style={{ display: "none" }}
                    />
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <div className="text-base text-black">{words["Phone Number"]}</div>
                    <Input
                      type="text"
                      name="store_number"
                      value={formData.store_number}
                      onChange={handleInput}
                      required
                    />
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <div className="text-base text-black">Password</div>
                    <Input
                      type="text"
                      name="store_password"
                      value={formData.store_password}
                      onChange={handleInput}
                      required
                    />
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <div className="text-base text-black">{words["ID Card"]}</div>
                    <button
                      type="button"
                      className="w-full h-[43px] rounded-[8px] bg-themeColor/30 text-[15px] font-semibold text-themeColor"
                      onClick={() => {
                        document.getElementById("IDCardUploadID")?.click();
                      }}
                    >
                      {idFile ? idFile.name : " Upload ID"}
                    </button>
                    <input
                      accept="image/jpeg, image/png, image/jpg, image/webp"
                      name="file-upload"
                      id="IDCardUploadID"
                      type="file"
                      className="sr-only"
                      onChange={handleUploadID}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex gap-6 mb-6">
              <div className="w-full grid-box px-4 py-4 border-solid border-[1px] border-[#D8D8D8] rounded-[20px] pb-12">
                <div className="w-full flex items-center mb-8">
                  <div className="text-base font-bold flex-1">{words["Bank Details"]}</div>
                </div>
                <div className="w-full grid grid-cols-3 gap-6">
                  <div className="w-full flex flex-col gap-2">
                    <div className="text-base text-black">{words["Bank Name"]} </div>
                    <Input
                      type="text"
                      name="bank_name"
                      value={formData.bank_name}
                      onChange={handleInput}
                      required
                    />
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <div className="text-base text-black">{words["Account Number"]} </div>
                    <Input
                      type="text"
                      name="account_number"
                      value={formData.account_number}
                      onChange={handleInput}
                      required
                    />
                  </div>
                  <div className="w-full flex flex-col gap-2">
                    <div className="text-base text-black">{words["Account Name"]} </div>
                    <Input
                      type="text"
                      name="account_name"
                      value={formData.account_name}
                      onChange={handleInput}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex items-center justify-end">
              {/* <button type="submit" className="bg-themeColor min-w-[200px] h-[47px] px-3 text-base text-center text-white font-semibold rounded-full">
                  Create Store
              </button> */}
              <input
                type="submit"
                value={response.isLoading ? "Loading..." : loading ? "Loading..." : words["Create store"]}
                className="bg-themeColor min-w-[200px] h-[47px] px-3 text-base text-center text-white font-semibold rounded-full cursor-pointer"
              />
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );


  // return (
  //   <Layout pageTitle="Create Store">
  //     <div className="w-full min-h-screen flex pb-10">
  //       <div className="wrap wrapWidth flex flex-col gap-6">
  //         <form onSubmit={handleSubmitSetting} className="w-full gap-6">
  //           <div className="w-full flex gap-6 mb-6">
  //             <div className="w-full grid-box px-5 pt-4 pb-12 border-solid border-[1px] border-[#D8D8D8] rounded-[20px] flex flex-col">
  //               <div className="w-full flex items-center mb-6">
  //                 <div className="text-base font-bold flex-1">
  //                   Store Information
  //                 </div>
  //                 <div className="flex items-center gap-1.5">
  //                   <div className="text-base">Active</div>
  //                   <ToggleButton checked={isActive} setChecked={setIsActive} />
  //                 </div>
  //               </div>
  //               <div className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
  //                 <div className="text-base text-black">Store Name</div>
  //                 <Input
  //                   placeholder="Store Name"
  //                   name="store_name"
  //                   value={formData.store_name}
  //                   onChange={handleInput}
  //                   required
  //                 />
  //               </div>
  //               <div className="w-full max-w-[400px] flex flex-col gap-2 mb-[20px]">
  //                 <div className="text-base text-black">Visiting Charges</div>
  //                 <Input
  //                   placeholder="Visiting Charges"
  //                   name="store_visting_charges"
  //                   value={formData.store_visting_charges}
  //                   onChange={handleInput}
  //                   required
  //                 />
  //               </div>
  //               <div className="w-full flex items-center gap-8 mb-[24px]">
  //                 <div className="flex items-center gap-1.5">
  //                   <div className="text-base">At Store</div>
  //                   <ToggleButton checked={atStore} setChecked={seAtStore} />
  //                 </div>
  //                 <div className="flex items-center gap-1.5">
  //                   <div className="text-base">At Home</div>
  //                   <ToggleButton checked={atHome} setChecked={setAtHome} />
  //                 </div>
  //               </div>
  //               <div className="flex flex-col gap-2">
  //                 <div className="text-base">About this store</div>
  //                 <textarea
  //                   className="input h-[110px]"
  //                   name="description"
  //                   value={formData.description}
  //                   onChange={handleInput}
  //                   placeholder="Enter About Store here..."
  //                 />
  //               </div>
  //             </div>
  //             <div className="w-full grid-box px-4 py-5 border-solid border-[1px] border-[#D8D8D8] rounded-[20px]">
  //               <div className="w-full flex items-center mb-6">
  //                 <div className="text-base font-bold flex-1">Images</div>
  //               </div>
  //               <div className="w-full flex flex-col mb-8">
  //                 <div className="text-base mb-2">Logo</div>
  //                 <div className="w-full flex gap-5">
  //                   <div className="w-full max-w-[250px]">
  //                     <UploadButton
  //                       label=""
  //                       text="Add image 80X20"
  //                       onClick={() => {
  //                         document.getElementById("uploadLogo")?.click();
  //                       }}
  //                     />
  //                     <input
  //                       accept="image/jpeg, image/png, image/jpg, image/webp"
  //                       name="file-upload"
  //                       id="uploadLogo"
  //                       type="file"
  //                       className="sr-only"
  //                       onChange={handleUploadLogo}
  //                       style={{ display: "none" }}
  //                     />
  //                   </div>
  //                   {logo && (
  //                     <img
  //                       src={window.URL.createObjectURL(logo)}
  //                       className="h-[130px] object-contain max-w-[200px]"
  //                     />
  //                   )}
  //                 </div>
  //               </div>
  //               <div className="w-full flex flex-col">
  //                 <div className="text-base mb-2">Photos</div>
  //                 <div className="w-full grid grid-cols-3 gap-3">
  //                   <div className="w-full flex flex-col gap-5">
  //                     <div className="w-full max-h-[135px]">
  //                       <UploadButton
  //                         label="Photo 1"
  //                         text=""
  //                         onClick={() => {
  //                           document.getElementById("uploadPhoto1")?.click();
  //                         }}
  //                       // className="max-h-[135px]"
  //                       />
  //                       <input
  //                         accept="image/jpeg, image/png, image/jpg, image/webp"
  //                         name="file-upload"
  //                         id="uploadPhoto1"
  //                         type="file"
  //                         className="sr-only"
  //                         onChange={(e) => {
  //                           if (e.target.files && e.target.files.length > 0) {
  //                             setPhoto1(e.target.files[0]);
  //                           }
  //                         }}
  //                         style={{ display: "none" }}
  //                       />
  //                     </div>
  //                     {photo1 && (
  //                       <img
  //                         src={window.URL.createObjectURL(photo1)}
  //                         className="h-[130px] object-contain max-w-[200px]"
  //                       />
  //                     )}
  //                   </div>
  //                   <div className="w-full flex flex-col gap-5">
  //                     <div className="w-full max-h-[135px]">
  //                       <UploadButton
  //                         label="Photo 2"
  //                         text=""
  //                         onClick={() => {
  //                           document.getElementById("uploadPhoto2")?.click();
  //                         }}
  //                       // className="max-h-[135px]"
  //                       />
  //                       <input
  //                         accept="image/jpeg, image/png, image/jpg, image/webp"
  //                         name="file-upload"
  //                         id="uploadPhoto2"
  //                         type="file"
  //                         className="sr-only"
  //                         onChange={(e) => {
  //                           if (e.target.files && e.target.files.length > 0) {
  //                             setPhoto2(e.target.files[0]);
  //                           }
  //                         }}
  //                         style={{ display: "none" }}
  //                       />
  //                     </div>
  //                     {photo2 && (
  //                       <img
  //                         src={window.URL.createObjectURL(photo2)}
  //                         className="h-[130px] object-contain max-w-[200px]"
  //                       />
  //                     )}
  //                   </div>
  //                   <div className="w-full flex flex-col gap-5">
  //                     <div className="w-full max-h-[135px]">
  //                       <UploadButton
  //                         label="Photo 3"
  //                         text=""
  //                         onClick={() => {
  //                           document.getElementById("uploadPhoto3")?.click();
  //                         }}
  //                       // className="max-h-[135px]"
  //                       />
  //                       <input
  //                         accept="image/jpeg, image/png, image/jpg, image/webp"
  //                         name="file-upload"
  //                         id="uploadPhoto3"
  //                         type="file"
  //                         className="sr-only"
  //                         onChange={(e) => {
  //                           if (e.target.files && e.target.files.length > 0) {
  //                             setPhoto3(e.target.files[0]);
  //                           }
  //                         }}
  //                         style={{ display: "none" }}
  //                       />
  //                     </div>
  //                     {photo3 && (
  //                       <img
  //                         src={window.URL.createObjectURL(photo3)}
  //                         className="h-[130px] object-contain max-w-[200px]"
  //                       />
  //                     )}
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="w-full flex gap-6 mb-6">
  //             <div className="w-full grid-box px-4 py-5 border-solid border-[1px] border-[#D8D8D8] rounded-[20px]">
  //               <div className="w-full flex items-center mb-8">
  //                 <div className="text-base font-bold flex-1">Working Days</div>
  //               </div>
  //               <div className="w-full flex flex-col gap-5">
  //                 {[
  //                   "sunday",
  //                   "monday",
  //                   "tuesday",
  //                   "wednesday",
  //                   "thursday",
  //                   "friday",
  //                   "saturday",
  //                 ].map((day, index) => (
  //                   <PerDay
  //                     key={index}
  //                     day={day}
  //                     isOn={formData[`is_${day}`]}
  //                     fromTime={formData[`${day}_from`]}
  //                     toTime={formData[`${day}_to`]}
  //                     onToggleChange={handleToggleChange}
  //                     onTimeChange={handleTimeChange}
  //                   />
  //                 ))}
  //               </div>
  //             </div>
  //             <div className="w-full grid-box px-4 py-4 border-solid border-[1px] border-[#D8D8D8] rounded-[20px]">
  //               <div className="w-full flex items-center mb-8">
  //                 <div className="text-base font-bold flex-1">
  //                   Location Information
  //                 </div>
  //               </div>
  //               <div className="w-full h-[220px] bg-[#EFEFEF] border-[1px] border-[#808080] rounded-[10px] flex items-center justify-center mb-4">
  //                 <div className="text-base text-[#808080]">Map</div>
  //               </div>
  //               <div className="w-full flex flex-col gap-2 mb-3">
  //                 <div className="">Current Location</div>
  //                 <Input
  //                   type="text"
  //                   name="current_location"
  //                   value={formData.current_location}
  //                   onChange={handleInput}
  //                   required
  //                 />
  //               </div>
  //               <div className="w-full flex flex-col gap-2">
  //                 <div className="">City</div>
  //                 <Input
  //                   type="text"
  //                   name="city"
  //                   value={formData.city}
  //                   onChange={handleInput}
  //                   required
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //           <div className="w-full flex gap-6 mb-6">
  //             <div className="w-full grid-box px-4 py-5 border-solid border-[1px] border-[#D8D8D8] rounded-[20px]">
  //               <div className="w-full flex items-center mb-8">
  //                 <div className="text-base font-bold flex-1">
  //                   Personal Information
  //                 </div>
  //               </div>
  //               <div className="w-full grid grid-cols-3 gap-6">
  //                 <div className="w-full flex flex-col gap-2">
  //                   <div className="text-base text-black">Name</div>
  //                   <Input
  //                     type="text"
  //                     name="user_name"
  //                     value={formData.user_name}
  //                     onChange={handleInput}
  //                     required
  //                   />
  //                 </div>
  //                 <div className="w-full flex flex-col gap-2">
  //                   <div className="text-base text-black">Email</div>
  //                   <Input
  //                     type="email"
  //                     name="store_email"
  //                     value={formData.store_email}
  //                     onChange={handleInput}
  //                     required
  //                   />
  //                 </div>
  //                 <div className="w-full flex flex-col gap-2">
  //                   <div className="text-base text-black">CR Certification</div>
  //                   <button
  //                     type="button"
  //                     className="w-full h-[43px] rounded-[8px] bg-themeColor/30 text-[15px] font-semibold text-themeColor"
  //                     onClick={() => {
  //                       document.getElementById("CRUploadID")?.click();
  //                     }}
  //                   >
  //                     {crFile ? crFile.name : " Upload CR"}
  //                   </button>
  //                   <input
  //                     accept=".pdf"
  //                     name="file-upload"
  //                     id="CRUploadID"
  //                     type="file"
  //                     className="sr-only"
  //                     onChange={handleUploadCR}
  //                     style={{ display: "none" }}
  //                   />
  //                 </div>
  //                 <div className="w-full flex flex-col gap-2">
  //                   <div className="text-base text-black">Phone Number</div>
  //                   <Input
  //                     type="text"
  //                     name="store_number"
  //                     value={formData.store_number}
  //                     onChange={handleInput}
  //                     required
  //                   />
  //                 </div>
  //                 <div className="w-full flex flex-col gap-2">
  //                   <div className="text-base text-black">Password</div>
  //                   <Input
  //                     type="text"
  //                     name="store_password"
  //                     value={formData.store_password}
  //                     onChange={handleInput}
  //                     required
  //                   />
  //                 </div>
  //                 <div className="w-full flex flex-col gap-2">
  //                   <div className="text-base text-black">ID Card</div>
  //                   <button
  //                     type="button"
  //                     className="w-full h-[43px] rounded-[8px] bg-themeColor/30 text-[15px] font-semibold text-themeColor"
  //                     onClick={() => {
  //                       document.getElementById("IDCardUploadID")?.click();
  //                     }}
  //                   >
  //                     {idFile ? idFile.name : " Upload ID"}
  //                   </button>
  //                   <input
  //                     accept="image/jpeg, image/png, image/jpg, image/webp"
  //                     name="file-upload"
  //                     id="IDCardUploadID"
  //                     type="file"
  //                     className="sr-only"
  //                     onChange={handleUploadID}
  //                     style={{ display: "none" }}
  //                   />
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="w-full flex gap-6 mb-6">
  //             <div className="w-full grid-box px-4 py-4 border-solid border-[1px] border-[#D8D8D8] rounded-[20px] pb-12">
  //               <div className="w-full flex items-center mb-8">
  //                 <div className="text-base font-bold flex-1">Bank Details</div>
  //               </div>
  //               <div className="w-full grid grid-cols-3 gap-6">
  //                 <div className="w-full flex flex-col gap-2">
  //                   <div className="text-base text-black">Bank Name</div>
  //                   <Input
  //                     type="text"
  //                     name="bank_name"
  //                     value={formData.bank_name}
  //                     onChange={handleInput}
  //                     required
  //                   />
  //                 </div>
  //                 <div className="w-full flex flex-col gap-2">
  //                   <div className="text-base text-black">Account Number</div>
  //                   <Input
  //                     type="text"
  //                     name="account_number"
  //                     value={formData.account_number}
  //                     onChange={handleInput}
  //                     required
  //                   />
  //                 </div>
  //                 <div className="w-full flex flex-col gap-2">
  //                   <div className="text-base text-black">Account Name</div>
  //                   <Input
  //                     type="text"
  //                     name="account_name"
  //                     value={formData.account_name}
  //                     onChange={handleInput}
  //                     required
  //                   />
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="w-full flex items-center justify-end">
  //             {/* <button type="submit" className="bg-themeColor min-w-[200px] h-[47px] px-3 text-base text-center text-white font-semibold rounded-full">
  //                 Create Store
  //             </button> */}
  //             <input
  //               type="submit"
  //               value={response.isLoading ? "Loading..." : "Create Store"}
  //               className="bg-themeColor min-w-[200px] h-[47px] px-3 text-base text-center text-white font-semibold rounded-full cursor-pointer"
  //             />
  //           </div>
  //         </form>
  //       </div>
  //     </div>
  //   </Layout>
  // );
};

export default StoreCreate;

export const en = {
    'home': 'Home',
    'users': 'Users',
    'stores': 'Stores',
    'bookings': 'Bookings',
    'finance': 'Finance',
    'banners': 'Banners',
    'featured': 'Featured',
    'notifications': 'Notifications',
    'settings': 'Settings',
    'logout': 'Logout',
    'income': 'Income',

    'no': 'No',
    'store name': 'Store Name',
    'phone': 'Phone',
    'email': 'Email',
    'id store': 'Id Store',
    'location': 'Location',
    'publishing': 'Publishing',
    'status': 'Status',
    'action': 'Action',
    'type': 'Type',
    'phone': 'Phone',
    'id': 'Id',
    'image': 'Image',
    'name': 'Name',
    'search': 'Search',
    'add store': 'Add Store',
    'publishing': 'Publishing',
    'general': 'General',
    'store information': 'Store information',
    'service list': 'Service list',
    'product list': 'Product list',
    'store details': 'Store details',

    'income': 'Income',
    'products': 'Products',
    'orders': 'Orders',
    'services': 'Services',
    'evaluation': 'Evaluation',
    "OMR": 'OMR',

    'Store Name': 'Store Name',
    'Visiting Charge': 'Visiting Charge',
    'Store Name': 'Store Name',
    'Address': 'Address',
    'City': 'City',
    'At Home': 'At Home',
    'At Store': 'At Store',
    'About Company': 'About Company',
    'Long Description': 'Long Description',
    'Name': 'Name',
    'Email': 'Email',
    'Phone': 'Phone',
    'Commission': 'Commission',
    'Sunday': 'Sunday',
    'Monday': 'Monday',
    'Tuesday': 'Tuesday',
    'Wednesday': 'Wednesday',
    'Thursday': 'Thursday',
    'Friday': 'Friday',
    'Saturday': 'Saturday',
    'Bank Name': 'Bank Name',
    'Account Number': 'Account Number',
    'Account Name': 'Account Name',

    'Logo': 'Logo',
    'Banner': 'Banner',
    'Company Details': 'Company Details',
    'Bank Details': 'Bank Details',
    'Personal Information': 'Personal Information',
    'Edit Store': 'Edit Store',
    'Active': 'Active',
    'featured sections': 'Featured sections',

    'Visiting Charges': 'Visiting Charges',
    'At Store': 'At Store',
    'At Home': 'At Home',
    'About this store': 'About this store',
    'Working Days': 'Working Days',
    'Images': 'Images',
    'Photos': 'Photos',
    'Location Information': 'Location Information',
    'Current Location': 'Current Location',
    'CR Certification': 'CR Certification',
    'ID Card': 'ID Card',
    "To": "To",
    "on": 'on',
    "off": 'off',
    "Map": "Map",
    'Phone Number': 'Phone Number',
    "save": "Save",
    'Create store': 'Create store',

    'Your email': 'Your email',
    'Type your password': 'Type your password',
    'Password': 'Password',
    'Sign in': 'Sign in',
    'Loading': 'Loading',
    'Created at': 'Created at',
    'Edit': 'Edit',
    "Add": "Add",
    'Delete': 'Delete',
    'Edit user': 'Edit user',
    'Add user': 'Add user',

    'Setting': 'Setting',
    'Terms & Privacy Settings': 'Terms & Privacy Settings',
    'Company Settings': 'Company Settings',
    'Company Title': 'Company Title',
    'Support Name': 'Support Name',
    'Support Email': 'Support Email',
    'Mobile': 'Mobile',
    'Select Time Zone': 'Select Time Zone',
    'Max Serviceable Distance (in Kms)': 'Max Serviceable Distance (in Kms)',
    'Country Code': 'Country Code',
    'Primary Color': 'Primary Color',
    'Secondary Color': 'Secondary Color',
    'Primary Shadow Color': 'Primary Shadow Color',
    'OTP System': 'OTP System',
    'Booking auto cancel Duration': 'Booking auto cancel Duration',
    'Login Image': 'Login Image',
    'Company Address': 'Company Address',
    'Company Short Description': 'Company Short Description',
    'Copyright Details': 'Copyright Details',
    'Support Hours': 'Support Hours',

    'Customer Terms and Conditions': 'Customer Terms and Conditions',
    'Customer privacy policy': 'Customer privacy policy',
    'Store Terms and Conditions': 'Store Terms and Conditions',
    'Store Privacy Policy': 'Store Privacy Policy',

    'Notification': 'Notification',
    'Title': 'Title',
    'Description': 'Description',
    'Date': 'Date',

    'Featured Section': 'Featured Section',
    'Rank': 'Rank',
    'Add Featured Section': 'Add Featured Section',
    'Section Types': 'Section Types',
    'Add Featured': 'Add Featured',
    'Add Banner': 'Add Banner',
    'Select': 'Select',
    'Store': 'Store',
    'User': 'User',
    'Edit Banner': 'Edit Banner',

    'Order Id': 'Order Id',
    'User name': 'User name',
    'Order amount': 'Order amount',
    'Admin commision': 'Admin commision',
    'Store amount': 'Store amount',
    'Transaction date': 'Transaction date',
    'Is Paid to store': 'Is Paid to store',
    'Paid': 'Paid',
    'Mark as paid': 'Mark as paid',

    'Booking Detail': 'Booking Detail',
    'Date of order': 'Date of order',
    'Payment status': 'Payment status',
    'Price': 'Price',
    'Quantity': 'Quantity',
    'Customer Details': 'Customer Details',
    'Contact Number': 'Contact Number',
    'Co %' : 'Co %',
    'Total' : 'Total',
    'Store Info' : 'Store Info',
    'Company Name' : 'Company Name',
    'User phone': 'User phone',
    'Paymnet status' : 'Paymnet status',
    'View' : 'View',
    'Timing Details' : 'Timing Details',
    'Are you sure you want to delete this store?' :'Are you sure you want to delete this store?',
    'Yes' : 'Yes',
    'No' : 'No',
    'Cancel' : 'Cancel',
    'Service Name' : 'Service Name',
    'ID Store' : 'ID Store',
    'Detail' : 'Detail',
    'View Service' : 'View Service',
    'Service Details' : 'Service Details',
    'Service Price': 'Service Price',
    'Basic Details' : 'Basic Details',
    'Tamsir Type' : 'Tamsir Type',
    'Tamsir Color' : 'Tamsir Color',
    'Tamsir Accessory': 'Tamsir Accessory',
    'Service description' : 'Service description',
    'Product Name' : 'Product Name',
    'Product Details' : 'Product Details',
    'View Product' : 'View Product',
    'store phone' : 'Store phone',
    'Send Notification' : 'Send Notification',
    'Post' : 'Post',




























}

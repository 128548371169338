import React, { useState } from "react";
import { Dropdown, Menu, Button, Typography } from "antd";
import Layout from "../../components/Layout";
import { DotMenuIcon, ReorderIcon } from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import Modal from "../../components/Modal";
import FeaturedModel from "../../components/FeaturedModel";
import { useGetFeaturesQuery, useUpdateRankMutation } from "../../store/services/featuredService";
import Loader from "../../components/Loader";

import DataTable from "../../components/DataTable";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../Api/constants";



const Featured = () => {

  const words = useSelector((state) => state.authReducer.words);
  const [openModal, setOpenModal] = useState(false);
  const { data: featuresList, isLoading } = useGetFeaturesQuery();
  const [submitData, { isLoading: rankLoading }] = useUpdateRankMutation();



  const [editModal, setEditModal] = useState(false);
  const [editModalItem, setEditModalItem] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState('');

  const [draggingItem, setDraggingItem] = useState();




  const columns = [
    {
      title: words["id"],
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: words["type"],
      dataIndex: 'type',
      key: 'type',
    },

    {
      title: words['Rank'],
      dataIndex: 'rank',
      key: 'rank',
    },

    {
      title: words["action"],
      key: 'action',
      render: (_, record) => (
        <Dropdown
          overlay={


            <Menu
              onClick={({ key }) => {
                if (key == 'edit') {
                  setEditModalItem(record)
                  setEditModal(true);
                }
                if (key == 'delete') {
                  setDeletedId(record.banner_id);
                  setOpenDeleteModal(true);
                }
              }}
            >
              <>
                <Menu.Item key="edit">Edit</Menu.Item>
                <Menu.Item key="delete">Delete</Menu.Item>
              </>

            </Menu>
          }
          trigger={['click']}
        >

          <Button>
            <div
              className="flex items-center justify-center cursor-pointer text-[#0025F2]"
            >
              <DotMenuIcon />
            </div>
          </Button>
        </Dropdown>
      ),
    },
  ];


  const changeOrder = (targetItem) => {
    // const { draggingItem, items } = this.state; 
    const items = featuresList.data;
    if (!draggingItem) return;
    const currentIndex = items.indexOf(draggingItem);
    const targetIndex = items.indexOf(targetItem);



    if (currentIndex !== -1 && targetIndex !== -1) {
      const body = {
        ranking_item_id: draggingItem.feat_id,
        target_item_id: targetItem.feat_id,
      };
      console.log('body', body);
      submitData(body);

      // items.splice(currentIndex, 1);
      // items.splice(targetIndex, 0, draggingItem);
      // this.setState({ items });
    }
  }


  return (
    <Layout pageTitle="Featured Section">
      {rankLoading ? (
        <div className="wrap wrapWidth flex flex-col items-center ">
          <Loader />
        </div>
      ) : (
        <div className="notification-page min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-5 mt-6">
              <div className="flex items-center justify-between gap-3">
                <div className="flex flex-1"></div>
                <div className="flex flex-1 justify-end">
                  <button
                    style={{ zIndex: 1 }}
                    className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]"
                    onClick={(e) => setOpenModal(true)}
                  >
                    <div className="flex items-center justify-center text-white font-normal text-2xl">
                      +
                    </div>
                    <label className="text-white cursor-pointer">{words['Add']}</label>
                  </button>
                </div>
              </div>
              {/* <div 
            className="responsive-table overflow-x-auto">
              {isLoading ? (
                <div className="flex items-center justify-center w-full">
                  <Loader />
                </div>
              ) : (
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-[#F5F7FB]">
                    <tr>
                      <th className="px-2 py-2 text-left text-sm font-medium text-black tracking-wider">
                        No
                      </th>

                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        Title
                      </th>
                      <th className="px-2 py-2 text-left w-52 break-words text-sm text-black font-medium">
                        Section Type
                      </th>
                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        Status
                      </th>
                      <th className="px-2 py-2 text-center text-sm text-black font-medium">
                        Reorder
                      </th>
                      <th className="px-2 py-2 text-center text-sm text-black font-medium">
                        Rank
                      </th>

                      <th className="px-2 py-2 text-left text-sm text-black font-medium">
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200">
                    {featuresList?.data?.map((item, index) => (
                      <tr
                        key={index}
                        className={`p-2 ${
                          index % 2 === 0 ? "bg-white" : "bg-white"
                        }`}
                      >
                        <td className="px-2 py-2 text-sm text-black font-medium">
                          {index + 1}
                        </td>
                        <td className="px-2 py-2 text-left whitespace-nowrap text-xs text-[#292D32]">
                          {item.title}
                        </td>
                        <td className="px-2 py-2 w-52 text-xs text-[#292D32]">
                          <div className="flex gap-3 break-words w-52">
                            {item.type}
                          </div>
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                          <div
                            className={`py-2 px-2 rounded-full text-center font-semibold ${
                              item.status === "Active"
                                ? "bg-[#0DB214]/40 text-[#0DB214]"
                                : "bg-red-200 text-red-400"
                            }`}
                          >
                            {item.status}
                          </div>
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                          <div className="flex items-center justify-center">
                            <ReorderIcon />
                          </div>
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap text-xs text-center text-[#292D32]">
                          {item.rank}
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap text-xs text-[#292D32]">
                          <div className="flex items-center justify-center cursor-pointer">
                            <DotMenuIcon />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div> */}

              <div style={{ marginTop: -50 }}>
                <DataTable
                  onDragStart={(e, item) => {
                    setDraggingItem(item);
                  }}
                  onDragEnd={() => { }}
                  onDrop={(e, targetItem) => changeOrder(targetItem)}
                  draggable={true}
                  searchContainerStyle={{ width: "40%", }}
                  data={featuresList?.data ?? []} columns={columns} />
              </div>


            </div>
          </div>
          <Modal open={openModal} setOpen={setOpenModal}>
            <FeaturedModel openModal={openModal} setOpenModal={setOpenModal} />
          </Modal>

          <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
            <DeleteConfirmationModel
              openModal={openDeleteModal}
              // onSubmit={() => doDeleteBanner()}
              setOpenModal={setOpenDeleteModal}
            />
          </Modal>
          <Modal open={editModal} setOpen={setEditModal}>
            {/* <EditBannerModel
            data={editModalItem}
            openModal={openModal} setOpenModal={setEditModal} /> */}
          </Modal>

        </div>

      )}
    </Layout>
  );
};

export default Featured;

import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  HomeIcon,
  StoreIcon,
  BooksIcon,
  UsersIcon,
  SubscriptionIcon,
  FinanceIcon,
  BannerIcon,
  BellIcon,
  SettingIcon,
  LogoutIcon,
} from "../../assets/Icons";
import { setAdminToken } from "../../store/reducers/authReducer";
const Sidebar = ({ openSidebar, setOpenSidebar }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const words = useSelector((state) => state.authReducer.words);
  const lang = useSelector((state) => state.authReducer.lang);
  
  const navBarItems = [
    { lbl: words["home"], icon: <HomeIcon />, slug: "/dashboard/home" },
    {
      lbl: words["stores"],
      icon: <StoreIcon />,
      slug: "/dashboard/stores",
    },
    { lbl: words["bookings"], icon: <BooksIcon />, slug: "/dashboard/booking" },
    { lbl:  words["users"], icon: <UsersIcon />, slug: "/dashboard/users" },
    // {
    //   lbl: "Subscriptions",
    //   icon: <SubscriptionIcon />,
    //   slug: "/dashboard/subscriptions",
    // },
    { lbl: words["finance"], icon: <FinanceIcon />, slug: "/dashboard/finance" },
  ];

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    // localStorage.setItem(null);
    // dispatch(setAdminToken(null));
    window.location.reload();
    navigate("/");
  };
  useEffect(() => {
    document.body.addEventListener("click", () => {
      document.body.style.overflowY = "auto";
      // setOpenSidebar(false);
    });
  }, []);

  return (
    <div 
      style={lang == 'ar' ?{right:0 }:{left:0}}
      className={`sidebar-s relative anim ${openSidebar ? "show" : "hide"} `}>
      <div
        className={`side-block flex flex-col anim rounded-xl ${openSidebar ? "show" : "hide"
          }`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="hdr flex items-center justify-center">
          <img src="/images/logo-w.svg" className=" h-16" />
        </div>
        <div className="flex flex-col px-4 h-full">
          <div className="items flex flex-col w-full gap-2">
            {navBarItems.map((item, index) => (
              <NavLink
                key={index}
                to={item.slug}
                className={`items-list flex gap-3 items-center py-2 px-5 rounded-full`}
              // onClick={(e) => {
              //   setOpenSidebar(false);
              // }}
              >
                <div className="icon flex items-start justify-center">
                  {item.icon}
                </div>
                <div className="li text-white">{item.lbl}</div>
              </NavLink>
            ))}
          </div>
          <div className="flex w-full border-b border-white" />
          <div className="items flex flex-col w-full gap-2">
            <NavLink
              to="/dashboard/banner"
              className={`items-list flex gap-3 items-center py-2 px-5 rounded-full`}
            >
              <div className="icon flex items-start justify-center">
                <BannerIcon />
              </div>
              <div className="li text-white">{words["banners"]} </div>
            </NavLink>
            <NavLink
              to="/dashboard/featured"
              className={`items-list flex gap-3 items-center py-2 px-5 rounded-full`}
            >
              <div className="icon flex items-start justify-center">
                <BannerIcon />
              </div>
              <div className="li text-white">{words["featured sections"]}</div>
            </NavLink>
            <NavLink
              to="/dashboard/notification"
              className={`items-list flex gap-3 items-center py-2 px-5 rounded-full`}
            >
              <div className="icon flex items-start justify-center">
                <BellIcon />
              </div>
              <div className="li text-white">{words["notifications"]}</div>
            </NavLink>
            <NavLink
              to="/dashboard/setting"
              className={`items-list flex gap-3 items-center py-2 px-5 rounded-full`}
            >
              <div className="icon flex items-start justify-center">
                <SettingIcon />
              </div>
              <div className="li text-white">{words["settings"]} </div>
            </NavLink>
          </div>
        </div>
        <div className="sidebar-footer">
          <button
            className={`items-list flex gap-3 items-center py-2 px-5 rounded-full`}
            onClick={(e) => logout()}
          >
            <div className="icon flex items-start justify-center">
              <LogoutIcon />
            </div>
            <div className="li text-white">{words["logout"]} </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Private from "./Private";
import Public from "./Public";

import Login from "../Pages/Login";
import Registration from "../Pages/Registration";

import Home from "../Pages/Home";
import Stores from "../Pages/Stores";
import StoreDetail from "../Pages/StoreDetail";
import Booking from "../Pages/Booking";
import BookingDetail from "../Pages/Booking/BookingDetail";
import Users from "../Pages/Users";
import Subscriptions from "../Pages/Subscriptions";
import Finance from "../Pages/Finance";
import Banner from "../Pages/Banner";
import Featured from "../Pages/Featured";
import Notification from "../Pages/Notification";
import Setting from "../Pages/Setting";
import ServiceDetail from "../Pages/ServiceDetail";
import ProductDetail from "../Pages/ProductDetail";
import StoreCreate from "../Pages/StoreCreate";
import EditStore from "../Pages/StoreCreate/EditStore";

const Routing = () => {
  return (
    <BrowserRouter>
      <ToastContainer position="top-center" />
      <Routes>
        <Route
          path="/"
          element={
            <Public>
              <Login />
            </Public>
          }
        />
        <Route path="auth">
          <Route
            path="login"
            element={
              <Public>
                <Login />
              </Public>
            }
          />
          <Route
            path="register"
            element={
              <Public>
                <Registration />
              </Public>
            }
          />
        </Route>
        <Route path="dashboard">
          <Route
            path="home"
            element={
              <Private>
                <Home />
              </Private>
            }
          />
          <Route
            path="stores"
            element={
              <Private>
                <Stores />
              </Private>
            }
          />
          <Route
            path="create-store"
            element={
              <Private>
                <StoreCreate />
              </Private>
            }
          />
          <Route
            path="store-detail/:id"
            element={
              <Private>
                <StoreDetail />
              </Private>
            }
          />
          <Route
            path="edit-store/:id"
            element={
              <Private>
                <EditStore />
              </Private>
            }
          />
          <Route
            path="service-detail/:id"
            element={
              <Private>
                <ServiceDetail />
              </Private>
            }
          />
          <Route
            path="product-detail/:id"
            element={
              <Private>
                <ProductDetail />
              </Private>
            }
          />
          <Route
            path="booking"
            element={
              <Private>
                <Booking />
              </Private>
            }
          />
          <Route
            path="booking/:id"
            element={
              <Private>
                <BookingDetail />
              </Private>
            }
          />
          <Route
            path="users"
            element={
              <Private>
                <Users />
              </Private>
            }
          />
          <Route
            path="subscriptions"
            element={
              <Private>
                <Subscriptions />
              </Private>
            }
          />
          <Route
            path="finance"
            element={
              <Private>
                <Finance />
              </Private>
            }
          />
          <Route
            path="banner"
            element={
              <Private>
                <Banner />
              </Private>
            }
          />
          <Route
            path="featured"
            element={
              <Private>
                <Featured />
              </Private>
            }
          />
          <Route
            path="notification"
            element={
              <Private>
                <Notification />
              </Private>
            }
          />
          <Route
            path="setting"
            element={
              <Private>
                <Setting />
              </Private>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
